//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC9q4YJ3UvviiCnxLF6pFeE0I1bdMemahk",
  authDomain: "patmira-fd6cc.firebaseapp.com",
  projectId: "bpatmira-fd6cc",
  storageBucket: "patmira-fd6cc.appspot.com",
  messagingSenderId: "319468123836",
  appId: "1:319468123836:web:025b7e215292bd7d1342b9",
  measurementId: "G-850FS0FXDK",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
